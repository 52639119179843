import { Card, Col, Row, Space, Typography } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { Artist } from '../../components/artist/Artist'
import DescriptionModal from '../../components/DescriptionModal'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { Booking as BookingModel } from '../../models/Booking'
import { TableParams } from '../../models/TableParams'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import {
  getBookings,
  selectBookings,
  setBookingQuery,
} from '../../reducers/BookingReducer'
import {
  getEventWithId,
  selectSelectedEvent,
} from '../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { initialQuery } from '../../utils/helpers/crud/models'
import { MetaDataKey } from '../../models/MetaData'
import dayjs from 'dayjs'
import RoadsheetGenerator from '../../modules/roadsheet/RoadsheetGenerator'
import HasAccess, { useHasAccess } from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import {
  ArrowRightOutlined,
  CalendarOutlined,
  HomeOutlined,
} from '@ant-design/icons'
import { Venue } from '../../components/venue/Venue'
import { Booking } from '../../components/booking/Booking'
import { Event } from '../../components/event/Event'
import SBTable from '../../components/SBTable'
import { Contact } from '../../components/contact/Contact'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Reporting } from '../../components/reporting/Reporting'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import AdvancingLinkManager from '../../modules/external-advancing/AdvancingLinkManager'
import { Help } from '../../components/help/Help'
import { PageTitle } from '@supplement-bacon/alela-uikit'

const { Title } = Typography

const LineUpPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()

  const event = useAppSelector(selectSelectedEvent())
  const bookings = useAppSelector(selectBookings())
  const { query, isLoading } = useAppSelector((state) => state.booking)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columnsToDisplay: Record<string, boolean> = {
    artist: true,
    price: hasAccess([AlelaPermission.viewPriceBooking]),
    type: hasAccess([AlelaPermission.viewPriceBooking]),
    memo: hasAccess([AlelaPermission.viewMemoDealBooking]),
    offerSheet: hasAccess([AlelaPermission.viewPriceBooking]),
    owner: hasAccess([AlelaPermission.viewPriceBooking]),
    actions: hasAccess([AlelaPermission.editBooking]),
  }

  const columns: ColumnsType<BookingModel> = [
    {
      key: 'artist',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Lineup.Artist,
      }),
      width: 200,
      render: (record: BookingModel) => <Artist.Avatar id={record.artist!} />,
    },

    {
      key: 'price',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Lineup.Price,
      }),
      render: (record: BookingModel) => <Booking.AmountsTag id={record.id} />,
    },

    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Lineup.Type,
      }),
      render: (record: BookingModel) => <Booking.TypeTag id={record.type!} />,
    },

    {
      key: 'memo',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Lineup.MemoDeal,
      }),
      width: 130,
      align: 'center',
      render: (record: BookingModel) =>
        record.internal_note && (
          <DescriptionModal
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Lineup.MemoDeal,
            })}
            content={record.internal_note}
          />
        ),
    },
    {
      key: 'offerSheet',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Booking.Table.OfferSheet,
      }),
      align: 'center',
      render: (record: BookingModel) =>
        record.file && (
          <Actions
            actions={['view']}
            entity={SBRMType.file}
            entityId={record.file}
          />
        ),
    },
    {
      key: 'owner',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Lineup.Owner,
      }),
      width: 50,
      align: 'center',
      render: (record: BookingModel) => (
        <Contact.Avatar id={record.owner ?? 0} type="condensed" />
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: BookingModel) => (
        <Space direction="horizontal">
          <RoadsheetGenerator eventId={event?.id ?? 0} bookingId={record.id} />
          <AdvancingLinkManager
            eventId={event?.id ?? 0}
            bookingId={record.id}
          />
          <Actions
            actions={['update', 'comment', 'delete']}
            entity={SBRMType.booking}
            entityId={record.id}
            metadata={[
              { key: MetaDataKey.eventId, value: event?.id },
              { key: MetaDataKey.deleteWithConfirmation, value: true },
            ]}
          />
        </Space>
      ),
    },
  ]

  const baseQuery: TableParams = {
    ...initialQuery,
    filters: { events: [event?.id!] },
    with: ['performances', 'artist'],
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BookingModel> | SorterResult<BookingModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination: { ...query.pagination, ...pagination },
      filters: { ...filters, ...{ events: [event?.id!] } },
      ...extractSortDataFromSorterResult<BookingModel>(sorter),
    }
    dispatch(setBookingQuery(newQuery))
    dispatch(getBookings(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    dispatch(setBookingQuery(baseQuery))
    dispatch(getBookings(baseQuery))
  }, [SBRMIsOpen])

  useEffect(() => {
    if (!isFirstRender && !SBRMIsOpen && event) {
      // After an update we re-fetch the event details
      dispatch(getEventWithId(event.id))
    }
  }, [SBRMIsOpen])

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listBooking]}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card
                bordered={false}
                styles={{ body: { paddingTop: 0, paddingBottom: 0 } }}
              >
                <Row gutter={[16, 16]} style={{ marginBottom: 30 }}>
                  <Col span={24}>
                    <Space
                      direction="horizontal"
                      style={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <Space direction="vertical">
                        <Title
                          level={3}
                          style={{ marginTop: 20, marginBottom: 0 }}
                        >
                          {event?.name}
                        </Title>
                        <Space>
                          <Title
                            level={5}
                            style={{ marginTop: 0, marginBottom: 0 }}
                          >
                            <CalendarOutlined style={{ marginRight: 5 }} />
                            {dayjs(event?.start_date).format(
                              'ddd	DD MMM YYYY - HH:mm'
                            )}{' '}
                            <ArrowRightOutlined />{' '}
                            {dayjs(event?.end_date).format(
                              'ddd	DD MMM YYYY - HH:mm'
                            )}
                          </Title>
                          <Event.StatusTag id={event?.status ?? 0} />
                        </Space>
                        <Space>
                          <Title
                            level={5}
                            style={{ marginTop: 0, marginBottom: 0 }}
                          >
                            <HomeOutlined style={{ marginRight: 5 }} />
                            {event?.venues?.map((venue) => (
                              <Venue.Tag key={venue} id={venue} />
                            ))}
                          </Title>
                        </Space>
                      </Space>
                      <Space direction="vertical">
                        <Actions
                          actions={['update']}
                          entity={SBRMType.event}
                          entityId={event?.id}
                        />
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
            <HasAccess permissions={[AlelaPermission.createReport]}>
              <Col span={24}>
                <Reporting.EventAlert event={event} />
              </Col>
            </HasAccess>
            <Col span={24}>
              <PageTitle
                title={intl.formatMessage({
                  id: LocalizationKeys.Page.EventDetails.Lineup.Title,
                })}
                level={5}
                badge={bookings.length}
                toolbar={
                  <Space>
                    <Actions
                      actions={['create']}
                      entity={SBRMType.booking}
                      metadata={[
                        { key: MetaDataKey.eventId, value: event?.id },
                      ]}
                    />
                    <Help.Button
                      article={[
                        HelpArticle.AddAndManageLineUp,
                        HelpArticle.HowToGenerateRoadsheet,
                      ]}
                    />
                  </Space>
                }
              />
              <SBTable
                entity={SBRMType.booking}
                emptyActionsMetadata={[
                  { key: MetaDataKey.eventId, value: event?.id },
                ]}
                scroll={{ x: 1000 }}
                columns={columns.filter(
                  (column) => columnsToDisplay[column.key! as string]
                )}
                rowKey={(record) => record.id}
                dataSource={bookings}
                loading={isLoading && !bookings.length}
                pagination={query.pagination}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </HasAccess>
      </Col>
    </Row>
  )
}

export default LineUpPage
