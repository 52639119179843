import { useEffect } from 'react'
import { SBRMType } from '../modules/sbrm/SBRMModel'
import { SBRMTypeInfos } from '../modules/sbrm/SBRMTypeInfos'
import { useAppDispatch, useAppSelector } from '../reducers/hooks'
import { SBActivityLog } from './SBActivityLog'
import { TableParams } from '../models/TableParams'
import { initialQuery } from '../utils/helpers/crud/models'
import {
  getActivityLogs,
  selectActivityLogs,
  setActivityLogQuery,
} from '../reducers/ActivityLogReducer'
import { Flex, theme } from 'antd'
import Title from 'antd/es/typography/Title'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

interface Props {
  entity: SBRMType
}

const ResourceHistory = ({ entity }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const {
    token: { colorBorder },
  } = theme.useToken()

  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const activities = useAppSelector(selectActivityLogs())

  useEffect(() => {
    if (!SBRMIsOpen) return

    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { [entity]: [resourceId ?? 0] },
      columnKey: 'date',
      order: 'ascend',
    }
    dispatch(setActivityLogQuery(baseQuery))
    dispatch(getActivityLogs(baseQuery))
  }, [SBRMIsOpen])

  return (
    <Flex
      gap={16}
      vertical
      style={{
        width: '100%',
        height: '100%',
        padding: 30,
        borderLeftColor: colorBorder,
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
      }}
    >
      <Title level={5} className="m-0">
        {intl.formatMessage({
          id: LocalizationKeys.Components.ResourceHistory.Title,
        })}
      </Title>
      <SBActivityLog activities={activities} />
    </Flex>
  )
}

export default ResourceHistory
