import { Button, Tag, Typography } from 'antd'
import { Release } from '../../models/Release'
import {
  CompassOutlined,
  EditOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Text } = Typography

export const ReleaseNotes: Release[] = [
  {
    version: 'v2.3.0',
    date: '03/04/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Association des contacts',
        description:
          "Associer des contacts à un booking n'a jamais été aussi simple. Vous pouvez maintenant importer les contacts de l'artiste sur ses bookings",
      },
      {
        type: 'new',
        title: 'Artist avatar',
        description:
          "Quand vous créer un artiste manuellement il n'a pour l'instant pas d'image, nous avons donc ajouté une jolie image sur la page artiste, parce qu'on aime le beau",
      },
      {
        type: 'new',
        title: 'Création de booking depuis la page artiste',
        description:
          'Créer un booking depuis une page artist devient un bonheur. Le champ évènement propose maintenant vos derniers évènements ajoutés et vous pouvez même rechercher par nom, pas mal hein ?',
      },
      {
        type: 'new',
        title: 'Calendar loader',
        description:
          "Une icône de chargement a fait son apparition au dessus du calendrier pendant les opérations sur les évènements. Si votre connexion internet n'est pas optimale, vous aurez au moins un truc sympa à regarder pendant que ça charge",
      },
      {
        type: 'new',
        title: 'SBRM button fait son apparition',
        description:
          "Besoin de rajouter une chambre rapidement ? Sur la page lineup, un bouton a fait son apparition pour ajouter une chambre à un booking, l'artiste est pré-rempli et ça gagne du temps, et ça on aime. Envie de pouvoir ajouter d'autres ressources plus rapidement ? Dîtes-le nous :)",
      },
      {
        type: 'new',
        title: 'Création de ground',
        description:
          "Vous l'attendiez, la voici pour vous: La création de ground est désormais disponible ! Un point de départ, un point d'arrivée, une heure, une durée, un chauffeur et une notes pour organiser tous vos transports. Il sera bientôt possible de créer des adresses personnalisé, choisir plus de type de lieu (aéroport, restaurants, …). Stay tuned …",
      },
      {
        type: 'fix',
        title: 'Suppression booking',
        description:
          "La suppression d'un booking est corrigée. Elle entraîne la suppression des resources associées (chambres, riders, contrats) et le détachement des contacts (sans les supprimer bien-sûr)",
      },
      {
        type: 'fix',
        title: 'Cycle de chargement des evenements',
        description:
          "La photo de l'évènement avait pris pour mauvaise habitude de flasher lorsqu'on navigue dans les différentes section de l'évènement; c'est maintenant de l'histoire ancienne",
      },
      {
        type: 'fix',
        title: 'Select des type de contact',
        description:
          "La recherche des types de contact lorsqu'on crée une association ne fonctionnait pas. Vous pouvez maintenant rechercher jusqu'au bout de la nuit",
      },
      {
        type: 'fix',
        title: 'Mot de passe oublié',
        description:
          "Réinitialiser son mot de passe pouvait vite devenir un parcours du combattant. Nos équipes on fait le grand ménage là-dedans … on a presque envie d'oublier son mot de passe maintenant",
      },
      {
        type: 'fix',
        title: 'Filtre du select de booking',
        description:
          "Lorsqu'on doit choisir un booking à la création d'une resource (comme les chambres, les riders ou encore les contrats) il pouvait apparaître des bookings qui n'étaient de l'évènement",
      },
      {
        type: 'fix',
        title: 'Statut par défaut sur un événement',
        description:
          "À la création de l'évènement le statuts draft n'était pas visible, c'est corrigé et franchement c'est mieux comme ça",
      },
      {
        type: 'fix',
        title: 'Filtre des infos des événements',
        description:
          "En naviguant d'un évènement à un autre il pouvait arriver de voir le lineup de l'évènement précédant sur le nouvel évènement; pas cool. Pour éviter tout problème on a serré la vis et la bon lineup est maintenant visible",
      },
      {
        type: 'fix',
        title: 'Carnet de contact',
        description:
          'Sur le carnet de contact, seuls les 10 derniers types de contact étaient disponibles pour filtrer. Vous pouvez maintenant tous les voir',
      },
    ],
  },
  {
    version: 'v2.4.0',
    date: '05/04/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Refonte de la page Ground',
        description:
          "Les ground se refont une beauté ! L'affichage est plus clair, plus joli, un vrai bijou. Il sera bientôt possible d'attacher un booking, des passagers et des détails de véhicule aux ground",
      },
      {
        type: 'new',
        title: 'Modification de la photo des venues',
        description:
          "Changer la photo d'une venue se fait maintenant en cliquant sur la photo actuelle, depuis la page détails du lieu",
      },
      {
        type: 'new',
        title: 'Refonte de la page Rooms',
        description:
          "La liste des chambres d'un évènement a été modifié pour une lecture plus intuitive",
      },
      {
        type: 'fix',
        title: 'Nombre de nuitées sur une room',
        description:
          "Le nombre de nuits d'une chambre pouvait être incorrect selon les heures d'arrivées et de départ; maintenant il n'y a plus de place au doute",
      },
    ],
  },
  {
    version: 'v2.5.0',
    date: '05/04/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Ajout de champs sur le Ground',
        description:
          "Les ground sont encore une fois à l'honneur. Il est maintenant possible d'ajouter les détails du véhicule, associer un booking et associer des passagers. Les ground sont maintenant triés par date de pickup; Allez, en voiture Simone !",
      },
      {
        type: 'new',
        title: 'La comptabilité fais son apparition',
        description:
          "Pas mal pour les ground mais la vraie star de cette version c'est la page Invoices de l'évènement: Vous pouvez désormais uploader vos factures en PDF, en les associant à un booking ou non. Notre technologie de pointe liera entre les lignes pour deviner votre avenir … euh plutôt le prix de la facture et d'autres informations utiles comme la référence et la date d'échéance. Vous avez ensuite la possibilité de modifier ces informations manuellement. ",
      },
      {
        type: 'fix',
        title: 'Création des événements',
        description:
          "La création d'évènement à la suite n'était pas possible à cause d'un bug obscure. Nous avons fait toute la lumière sur la situation et vous pouvez maintenant créer des évènements sans fin jusqu'au bout de la nuit",
      },
      {
        type: 'fix',
        title: 'Recherche menus déroulants',
        description:
          "La recherche dans les menu déroulants pouvait s'avérer capricieuse. Nous avons resserré la vis et c'est maintenant une affaire qui roule",
      },
    ],
  },
  {
    version: 'v2.6.0',
    date: '07/04/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Téléchargement fichiers',
        description:
          'Tout fichier est téléchargeable en one click sur les cellules ! ',
      },
      {
        type: 'new',
        title: 'Indicateurs financiers facturation',
        description:
          'Leș petites card de totaux sur la page invoice fonctionnent maintenant',
      },
      {
        type: 'new',
        title: 'Création de facture',
        description: 'On peut maintenant créer une facture manuellement !',
      },
      {
        type: 'fix',
        title: 'Facturation',
        description: 'Arrondi des prix de factures',
      },
      {
        type: 'fix',
        title: 'Chargement des factures',
        description:
          "Correction d'un bug qui faisait flasher la liste des factures sur l'event detail",
      },
      {
        type: 'fix',
        title: 'SBRM button',
        description:
          'Le bouton SBRM sur une ligne de lineup fonctionne maintenant parfaitement',
      },
      {
        type: 'fix',
        title: 'Calcuateur de paiement',
        description:
          "Correction d'un bug qui ne resetait pas le calculateur de paiement sur le volet d'edition d'une facture",
      },
    ],
  },
  {
    version: 'v2.7.0',
    date: '14/04/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Master page financial',
        description:
          "Une nouvelle page fait son apparition: Financials ! Vous aller y retrouvez toutes vos factures avec la possibilité de les trier et de les filtrer pour ne jamais louper une échéanceÀ noter que seules les factures avec une date d'échéance apparaissent sur cette nouvelle page. Modifiez vos factures déjà crées pour pouvoir les y retrouver. Pour la création de nouvelles factures, si aucune date n'est fournie elle sera automatiquement mise à la date de fin d'évènement + 30 jours",
      },
      {
        type: 'new',
        title: 'SBRM button',
        description:
          "Vous pouvez désormais ajouter une facture à un booking directement depuis la page Lineup via le bouton d'ajout rapide",
      },
      {
        type: 'new',
        title: 'Raccourcis clavier',
        description:
          "Vous aimez la recherche ? Il est maintenant encore plus simple d'y accéder ! Pressez simultanément les touches Ctrl & F de votre clavier et la recherche apparaitra … comme par magie",
      },
      {
        type: 'new',
        title: 'Refontes diverses',
        description:
          "Parce qu'on aime le beau, on a mis quelques coups de pinceau ici et là pour vous proposer une interface toujours plus épurée",
      },
      {
        type: 'new',
        title: 'Au revoir les venues',
        description: 'La pages Venues a été déplacé dans la partie Settings',
      },
      {
        type: 'new',
        title: 'Table du temps',
        description:
          "Puisqu'on est pas avares de nouvelle fonctionnalités on va pas se quitter comme ça … (One more thing … vous avez la ref ?) Une nouvelle section a pris place dans le détail des évènements … La Timetable ! Avec une interface simple, glissez et déplacez les performances des artistes est un jeu d'enfant.",
      },
      {
        type: 'fix',
        title: 'Update des événements sur le calendrier',
        description:
          "Lors du déplacement d'un évènement depuis le calendrier, il pouvait arriver que l'évènement se remette à son ancienne place avant d'arriver à sa nouvelle date. C'est maintenant de l'histoire ancienne, il restera maintenant ou vous le déplacerez",
      },
    ],
  },
  {
    version: 'v2.8.0',
    date: '27/04/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Partage de fichiers',
        description:
          'Le partage de fichier est maintenant possible ! Vous pouvez maintenant envoyer ce rider à votre prestataire, ou ce contrat douteux à votre avocat ... Les liens publiques expirent automatiquement au bout de 15 jours.',
      },
      {
        type: 'new',
        title: 'Ajout des Travels 🚄🛩️',
        description:
          "L'ajout des billets de train ou d'avion est maintenant possible dans la section Production > Travels des évènements ! Entrez la référence du billet avec la date du trajet et les dêtails seront automatiquement récupérés pour vous !",
      },
      {
        type: 'new',
        title: 'Des ground pour les relier tous',
        description:
          'Les travels se retrouvent bien-sûr dans la section Ground ! Ils sont déjà séparés en deux catégories: Inbound & Outbound pour faciliter la création des ground',
      },
      {
        type: 'new',
        title: 'Création rapide',
        description:
          "Il est maintenant possible de créer un contact et un artiste directement depuis le sélecteur! Plus besoin de s'arrêter, aller le créer, et revenir. On gagne du temps, et le temps c'est de l'argent ;)",
      },
      {
        type: 'new',
        title: 'Association rapide des contacts',
        description:
          "À l'ajout de contact sur un évènement une nouvelle option est disponible pour associer ce contact avec les venues de l'évènement. Même chose pour un booking et l'artiste associé.",
      },
      {
        type: 'new',
        title: 'Les évènements passés se font discrets',
        description:
          "L'opacité des évènements passés a été réduite sur le calendrier pour vous permettre de repérer les évènements à venir d'un seul coup d'oeil.",
      },
      {
        type: 'new',
        title: 'Téléchargement des fichiers',
        description:
          'Un bouton télécharger a été ajouté sur les fichiers pour pouvoir les télécharger en un clic !',
      },
      {
        type: 'new',
        title: 'Embelissage des réglages',
        description:
          "La page Réglages a été complètement repensée pour être plus belle, parce qu'on aime quand c'est beau. Et on aime pas quand c'est laid.",
      },
      {
        type: 'fix',
        title: 'Emmêlage de pinceaux sur la page artiste',
        description:
          "Les pages artistes avaient la fâcheuse tendance d'afficher de mauvais fichiers. C'est résolu - En plus on a mis un coup de pinceau sur cette page et franchement c'est pas piqué des annetons.",
      },
      {
        type: 'fix',
        title: 'Affichage de chiffres dans les menus déroulants',
        description:
          "À la modification de certaines resources, il était possible de voir des chiffres dans les menus déroulants à la place du nom des resources. Il suffisait d'un coup de baguette magique pour résoudre ça !",
      },
    ],
  },
  {
    version: 'v2.10.0',
    date: '18/09/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Adresse libre',
        description:
          "Il est maintenant possible d'ajouter une adresse libre sur un ground",
      },
      {
        type: 'new',
        title: 'Save the date',
        description: 'Le calendrier garde maintenant en mémoire le mois visité',
      },
      {
        type: 'new',
        title: "Création d'évènement simplifiée",
        description:
          "Lors de la création d'un évènement les heures de début et fin se mettent automatiquement sur les heures d'ouverture du lieu. Vous pouvez modifier les heures d'ouverture par défaut du lieu sur la page du lieu",
      },
      {
        type: 'new',
        title: 'Smart Party Crew',
        description:
          "Le remplissage du Party Crew n'a jamais été aussi rapide! Tous les contacts associés aux resources du bookings (travels, rooms, grounds) et pas encore présent dans le Party Crew sont ajoutable en un clic",
      },
      {
        type: 'fix',
        title: 'Des bookings... encore des bookings',
        description:
          "Les bookings sur la page artistes s'affichaient dans un ordre aléatoire. Ils sont maintenant affichés par date d'évènement décroissante",
      },
      {
        type: 'fix',
        title: 'Une histoire de sous',
        description:
          "Le prix du dernier booking d'un artiste correspondait au dernier booking ajouté sur Alela, quelque soit la date de l'évènement. Source de confusion, il s'agit mainteant du booking avec la date d'évènement la plus grande dans le temps",
      },
      {
        type: 'fix',
        title: 'Recherche des hôtels',
        description:
          "La recherche par nom d'hôtel ne fonctionnait pas lors de l'ajout d'une chambre. C'est réparé!",
      },
      {
        type: 'new',
        title: 'Informations fees artistes',
        description:
          'Retrouvez les indicateurs last fee et average fee sur le listing des artistes',
      },
    ],
  },
  {
    version: 'v2.11.0',
    date: '11/10/2023',
    fixtures: [
      {
        type: 'new',
        title: "Des PDF comme vous n'en avez jamais vu 👀",
        description:
          "Une visionneuse de PDF est maintenant disponible directement dans Alela ! Plus besoin de télécharger le fichier pour voir de quoi il s'agit",
      },
      {
        type: 'new',
        title: 'Dupliquez moi tout ça !',
        description:
          'Pour les resources que vous créez souvent un nouveau raccourci est maintenant disponible ... le Dupliquer ! Disponible pour les chambres, travels & grounds. On va encore gagner du temps dis-donc...',
      },
      {
        type: 'new',
        title: 'Toujours plus loin... toujours plus fort...',
        description:
          "Il est maintenant possible de créer une resource directement l'éditeur de resource. Vous êtes entrain de rajouter une chambre et il vous manque un hôtel ? Plus besoin de tout quitter pour aller le créer ;)",
      },
      {
        type: 'new',
        title: 'Swap 🔛 those grounds!',
        description:
          "Lors de l'édition d'un ground vous pouvez maintenant inverser le départ et l'arrivée en 1 clic ! Que du bonheur...",
      },
      {
        type: 'new',
        title: "Recherche d'adresse avec autocomplétion",
        description:
          'Fatigué de remplir tous les champs des adresses ? Ce temps est révolu ! Entrez les premiers caractères et choississez parmi la myriade de résultats disponibles',
      },
    ],
  },
  {
    version: 'v2.12.0',
    date: '20/10/2023',
    fixtures: [
      {
        type: 'new',
        title: '💶 Refonte de la partie financière 📈',
        description: (
          <div>
            Les factures bénéficient d&apos;un sacré lifting avec cette nouvelle
            version: ajout du montant hors taxe, possibilité d&apos;attacher un
            fournisseur, une catégorie de dépense et des paiements !
            <br />
            Les paiements permettent de garder trace de son historique de
            réglement. On peut même attacher ses preuves de paiement pour les
            retrouver plus tard ou en faire un{' '}
            <Text type="secondary" italic>
              public link
            </Text>{' '}
            à envoyer, wahoo.
            <br />
            Les listes de factures ont été mise à jour pour tenir compte de ces
            nouveaux attributs, dans la tab{' '}
            <Text type="secondary" italic>
              Financials
            </Text>{' '}
            comme dans la liste des factures d&apos;un évènement
          </div>
        ),
      },
      {
        type: 'new',
        title: "Edition d'évènement",
        description:
          'Le bouton Modifier un évènement a été déplacé de la page Settings à la page Line-up',
      },
    ],
  },
  {
    version: 'v2.13.0',
    date: '04/11/2023',
    fixtures: [
      {
        type: 'new',
        title: '👥 Il est venu le temps du CRM 🕺',
        description: (
          <div>
            L&apos;onglet Contacts devient CRM !
            <br />
            Retrouvez maintenant tous vos Contacts, Suppliers & Team Members à
            un seul endroit !
            <br />
            <br />
            <Text type="secondary" strong>
              Contacts
            </Text>
            <br />
            <Text type="secondary">
              La nouvelle page détails d&apos;un contact permet de voir
              d&apos;un coup d&apos;oeil les relations du contact avec les
              artistes, venues & suppliers
            </Text>
            <br />
            <br />
            <Text type="secondary" strong>
              Suppliers
            </Text>
            <br />
            <Text type="secondary">
              Une liste de tous les Suppliers enregistrés avec leur solde de
              facturation.
              <br />
              Une page détails permet d&apos;attacher des contacts au Supplier
              et de retrouver toutes ses factures
            </Text>
            <br />
            <br />
            <Text type="secondary" strong>
              Team Members
            </Text>
            <br />
            <Text type="secondary">
              La liste des utilisateurs de votre organisation est déplacée dans
              cette nouvelle section CRM
              <br />
              La page détail d&apos;un utilisateur montre ses dernières actions
              et les évènements sur lesquels il a été attaché
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Du bon son dans les oreilles',
        description:
          "Résolution d'un bug sur Safari pouvant faire afficher le lecteur Spotify d'un autre artiste sur la page de détails d'un artiste",
      },
    ],
  },
  {
    version: 'v2.14.0',
    date: '24/11/2023',
    fixtures: [
      {
        type: 'new',
        title: "Refonte de l'onboarding 🚀",
        description: (
          <div>
            <Text type="secondary">
              L&apos;ajout de nouveaux team members a été complétement refondu !
              <br />
              Les invitations font leur apparition pour différencier les
              utilisateurs en attente et ceux déjà dans votre équipe
              <br />
              Un nouveau rôle utilisateur voit le jour, Marketing! Que de
              nouvelles possibilités...
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Point trop n'en faut",
        description:
          "Lors de l'édition des ground l'heure s'affiche maintenant sans les secondes. On va y voir plus clair dans tout ça!",
      },
      {
        type: 'new',
        title: 'Ils sont partout...',
        description:
          "Lors de l'édition des ground les passagers s'affichent en dessous des infos du travel. Pratique pour choisir quand il y a beacoup de travel",
      },
    ],
  },
  {
    version: 'v2.15.0',
    date: '12/12/2023',
    fixtures: [
      {
        type: 'new',
        title: 'Elles sont là 🎯',
        description: (
          <div>
            <Text type="secondary">
              Les notifications font leur entrées dans Alela!{' '}
              <Text type="secondary" italic>
                Alelaaaaaaaa
              </Text>
              <br />
              Restez connecté avec votre équipe et ne ratez jamais plus ce qui
              se passe. Vous recevrez en temps réel les actions clés de vos team
              mates
              <br />
              Retrouvez les notifications en haut à droite en cliquant sur
              l&apos;icône de la cloche ! N&apos;est-elle pas mignonne ?
              <br /> Vous recevrez aussi les notifications par email, pour être
              sûr de ne rien rater.
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Des statuts comme s'il en pleuvait 🤟",
        description:
          "Il est maintenant possible d'associer un statuts aux contrats & aux riders! Un champs 'notes' fait aussi son apparition pour pouvoir y laisser du texte libre, comme ça nous vient. Un endroit pour s'exprimer librement, dire ce qu'on a sur le coeur...",
      },
      {
        type: 'new',
        title: 'Du filtre, du filtre et encore du filtre',
        description:
          'Sur les pages des grounds & des travels, il est maintenant possible de filtrer les resources par booking',
      },
      {
        type: 'fix',
        title: 'Ajout de facture depuis un évènement',
        description:
          "Lors de l'ajout d'une facture depuis un évènement il fallait sélectionner l'évènement dans le formulaire de création de facture. La communication entre eux est rétablie et le sélecteur d'évènement n'apparaît plus. Encore un clic de gagné!",
      },
    ],
  },
  {
    version: 'v2.16.0',
    date: '10/01/2024',
    fixtures: [
      {
        type: 'new',
        title: "Besoin d'un coup de main ? 🤜🤛",
        description: (
          <div>
            <Text type="secondary">
              Un nouvel icône fait son apparition dans la barre du haut:{' '}
              <Text>
                <QuestionCircleOutlined />
              </Text>
              <br />
              En un clic accédez à nos guides pour maîtriser l&apos;outil comme
              personne !
              <br />
              Vous avez aussi la possibilité de reporter un bug ou nous poser
              une question
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Notifications non lues',
        description:
          "Un bug s'était glissé dans la liste des notifications pouvant empêcher des les marquer commes lues. Après une lutte acharné nous nous en sommes débarassé. Objectif Inbox Zero !",
      },
    ],
  },
  {
    version: 'v2.17.0',
    date: '15/01/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Dites-le avec des mots 💬',
        description: (
          <div>
            <Text type="secondary">
              Une nouvelle fonctionnalité fait son apparition dans Alela: les
              commentaires !
              <br />
              Communiquez facilement avec les membres de votre équipe. Vous avez
              la possibilité de mentionner un utilisateur avec <Tag>@</Tag>.
              <br />
              Vous recevrez une notification à chaque fois que vous êtes
              mentionné dans un commentaire
              <br />
              Cette nouvelle fonctionnalité est disponible sur les bookings,
              contrats & riders
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Clic sur les notifications système',
        description:
          "Un clic sur les notifications système n'entrainait aucune action. Désormais en cliquant sur la notification vous serez amenés à la resource en question en deux temps trois mouvements !",
      },
    ],
  },
  {
    version: 'v2.18.0',
    date: '23/01/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Oui oui baguette 🥖',
        description: (
          <div>
            <Text type="secondary">
              Parce que la langue de Molière est si belle... Alela est désormais
              disponible en français 🇫🇷
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Dites-le avec des GIF 🖼️',
        description: (
          <div>
            <Text type="secondary">
              Parce qu&apos;une image vaut mille mots
              <br />
              Les GIFs font leur apparition dans les commentaires
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Toujours au courant 📬',
        description: (
          <div>
            <Text type="secondary">
              Vous avez posté un commentaire mais vous avez peur de rater la
              réponse ?
              <br />
              Dès maintenant, lorsque vous postez un commentaire vous êtes
              abonné à la conversation et recevrez une notification
              lorsqu&apos;un autre utilisateur poste un commentaire
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Sur tous les fronts',
        description: (
          <div>
            <Text type="secondary">
              Mise en situation: Jeudi, 11h58, la faim vous tiraille, un nouveau
              contrat est uploadé et vous êtes tagué dans les commentaires. Vous
              ouvrez la notification, échangez 2/3 messages et là, hop,
              c&apos;est le moment de changer le statut du contrat. Comment
              faire ? Il faut fermer le visualisateur de commentaires, puis
              allez sur la page de l&apos;évènement, puis aller sur les
              contrats, puis ... olala c&apos;est loooong.
              <br />
              Alors qu&apos;avec cette nouvelle version, lorsque vous
              editez/commentez un fichier, vous pouvez passer de l&apos;un à
              l&apos;autre en 1 unique clic grâce au raccourci en haut à droite
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.19.0',
    date: '19/02/2024',
    fixtures: [
      {
        type: 'new',
        title: "Dîtes bonjour aux Rapports d'évènement",
        description: (
          <div>
            <Text type="secondary">
              Garder trace de la rentabilité de vos évènements grâce à cette
              nouvelle fonctionnalité
              <br />
              Entrez vos postes de dépense et de recette puis laissez faire la
              magie d&apos;Alela
              <br />
              De beaux graphiques viendront pointer les points forts & points
              faibles de vos évènements pour affiner votre stratégie
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "De l'aide en veux-tu en voilà",
        description: (
          <div>
            <Text type="secondary">
              L&apos;icône d&apos;aide{' '}
              <Text>
                <QuestionCircleOutlined />
              </Text>{' '}
              apparaît un peu partout pour vous épauler dans toutes situations.
              <br />
              Retrouver tous les tutoriel sur notre page dédiée{' '}
              <Link to={'https://help.alela.app'} target="_blank">
                <Text color="white" underline>
                  help.alela.app
                </Text>
              </Link>
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.20.0',
    date: '11/03/2024',
    fixtures: [
      {
        type: 'new',
        title: 'TODO or not TODO',
        description: (
          <div>
            <Text type="secondary">
              Les TODO liste font leur entrée dans Alela
              <br />
              Retrouvez-les dans l&apos;onglet <Text>Tâches</Text> des
              évènements
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'La pluie et le beau temps',
        description: (
          <div>
            <Text type="secondary">
              Les conditions météorologiques sont automatiquement ajoutés à
              chaque rapport d&apos;évènement.
              <br />
              Veillez à ce que la localisation de vos{' '}
              <Link to={'/settings/venues'}>
                <Text color="white" underline>
                  lieux
                </Text>
              </Link>{' '}
              soit bien définie
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Vue d'ensemble des rapports d'évènement",
        description: (
          <div>
            <Text type="secondary">
              L&apos;onglet <Text color="white">Finances</Text> gagne en
              fonctionnalité avec cette nouvelle version
              <br />
              Il est maintenant possible de passer de la vue d&apos;ensemble des
              factures à la vue d&apos;ensemble des rapports d&apos;évènement
              <br />
              Vous pouvez désormais analyser l&apos;évolution de vos indicateurs
              financiers dans le temps
              <br />
              Retrouvez cette vue d&apos;ensemble en cliquant{' '}
              <Link to={'/financials/reports'}>
                <Text color="white" underline>
                  ici
                </Text>
              </Link>
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.21.0',
    date: '18/03/2024',
    fixtures: [
      {
        type: 'fix',
        title: 'Suppression des lieux',
        description: (
          <div>
            <Text type="secondary">
              Un bug empêchant la suppression des lieux s&apos;était subtilement
              invité. On l&apos;a gentiellement racocmpagné d&apos;où il venait.
              Non mais oh ... c&apos;est fou ça quand même.
              <br />
              Pour la peine on a fait un truc super chouette: L&apos;archivage
              des lieux!
              <br />
              Les évènements restent, ce qui permet de ne pas perdre son
              historique et le lieu supprimé n&apos;apparaît plus dans les
              sélecteurs de lieux
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: "Suppresion d'un membre d'équipe",
        description: (
          <div>
            <Text type="secondary">
              Parce que toutes les bonnes choses ont une fin il faut savoir dire
              au revoir; et on avait un peu du mal jusqu&apos;ici.
              <br />
              Rassurez-vous, vous pouvez maintenant supprimer sans crainte un
              compte utilisateur
              <br />
              Et bon vent ...
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Modification de l'adresse email",
        description: (
          <div>
            <Text type="secondary">
              Il est maintenant possible de modifier son adresse email via la{' '}
              <Link to={'/account/profile'}>
                <Text underline color="white">
                  page profile
                </Text>
              </Link>
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.22.0',
    date: '26/03/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Pré-remplissage des grounds',
        description: (
          <div>
            <Text type="secondary">
              Lors de la création d&apos;un ground depuis un trajet inbound, la
              date de départ est automatiquement remplie avec l&apos;heure du
              ground
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Liste des factures',
        description: (
          <div>
            <Text type="secondary">
              Retrouvez la liste de vos factures Alela directement depuis la
              page de{' '}
              <Link to={'/settings/organization'}>
                <Text color="white" underline>
                  votre organisation
                </Text>
              </Link>
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Ajoût du rôle sur l'invitation",
        description: (
          <div>
            <Text type="secondary">
              Lors de l&apos;invitation d&apos;un nouveau membre d&apos;équipe
              vous pouvez maintenant lui associer directement un rôle
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.23.0',
    date: '29/03/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Calcul automatique des durées de grounds',
        description: (
          <div>
            <Text type="secondary">
              Lors de la création/modification d&apos;un ground, la durée peut
              maintenant être calculée automatiquement en cliquant sur
              l&apos;icône{' '}
              <Text color="white">
                <CompassOutlined />
              </Text>
              .
              <br />
              Pour que la durée soit calculée automatiquement les lieux de début
              et d&apos;arrivée doivent être renseignés et reliés par une route
              (à moins que vous fassiez vos grounds en bateau, mais là
              c&apos;est une autre histoire). Une heure de pickup ou
              d&apos;arrivée dans le futur doit aussi être renseignée
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Disparition des secondes sur les travels',
        description: (
          <div>
            <Text type="secondary">
              Les champs heure de départ et d&apos;arrivée des travels
              affichaient les secondes. Pourquoi ? Bonne question ... Bref,
              c&apos;est fini
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.24.0',
    date: '09/04/2024',
    fixtures: [
      {
        type: 'new',
        title: "Ajout des infos des chambres d'hôtel sur la Feuille De Route",
        description: (
          <div>
            <Text type="secondary">
              Les dates des chambres d&apos;hôtel ainsi que le nombre de nuits
              appairaissent maintenant sur la Feuille De Route
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title:
          "Correction de l'affichage de la timetable sur la Feuille De Route",
        description: (
          <div>
            <Text type="secondary">
              Dans le cas de B2B ou d&apos;artistes jouant plusieurs fois, il
              arrivait que l&apos;affichage de la timetable soit incorrect. Tout
              ceci est du passé, maintenant place à l&apos;avenir ...
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.25.0',
    date: '19/04/2024',
    fixtures: [
      {
        type: 'fix',
        title: 'Changement de statut de contrat impossible',
        description: (
          <div>
            <Text type="secondary">
              Le sélecteur de statuts de contrat pouvait, lorsque les planètes
              s&apos;aligaient, ne pas afficher tous les statuts disponibes. En
              deux coups de cuillère à pot le problème est résolu
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: "Problème lors de l'ouverture de notification de contrat",
        description: (
          <div>
            <Text type="secondary">
              Dans certains cas, l&apos;ouverture des notifications relatives à
              un contrat montraient une page noire. En trifouillant deux trois
              trucs on a réussi à remettre les choses d&apos;aplomb. C&apos;est
              quand même plus sympa ...
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.26.0',
    date: '09/05/2024',
    fixtures: [
      {
        type: 'new',
        title: "Introduction des liens d'Advancing externes",
        description: (
          <div>
            <Text type="secondary">
              Grâce aux liens d&apos;Advancing externes vous pouvez donner un
              accès à une personne extérieure à votre équipe pour compléter
              toute la partie production (chambres d&apos;hôtel, travels,
              grounds, rider). Il est aussi possible de compléter la partie
              administrative en téléversant directement les contrats et les
              factures. Pour les factures il est même possible d&apos;en
              retrouver les preuves de paiments.
              <br /> Comment accéder à cette nouvelle fonctionnalité ? Rien de
              plus simple, depuis la page Line-up de votre évènement cliquez sur
              le bouton <Button type="primary" icon={<LinkOutlined />} /> pour
              accéder à l&apos;éditeur de lien
              <br />
              Chaque section est paramétrable pour donner le juste niveau
              d&apos;accès à chacun. Vous avez même un chat pour en finir avec
              ces boucles mails interminables ;)
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Amélioration de la visionneuse de fichier 📸',
        description: (
          <div>
            <Text type="secondary">
              La visionneuse de fichier affiche maintenant un aperçu des images,
              pratique !
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Attention au passage d'un train 🚄",
        description: (
          <div>
            <Text type="secondary">
              Lorsque vous recherchez un voyage en train, il est désormais
              possible de choisir les gares de départ et d&apos;arrivée parmis
              celles du trajet. Cliquez simplement sur le bouton
              <Button icon={<EditOutlined />} /> pour accéder à toutes les gares
              du trajet
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Erreur lors de la recherche de voyage en train',
        description: (
          <div>
            <Text type="secondary">
              Dans certains cas, la recherche de voyage en train affichait une
              erreur alors qu&apos;il n&apos;y avait simplement pas de
              correspondances avec la référence entrée. Pour éviter les
              confusions le message <Text italic>Pas de voyage trouvé</Text>{' '}
              s&apos;affiche maintenant comme attendu
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.26.1',
    date: '10/05/2024',
    fixtures: [
      {
        type: 'new',
        title: "Création de rapport d'évènement avant leur date de fin",
        description: (
          <div>
            <Text type="secondary">
              Envie d&apos;avoir une vue d&apos;ensemble de vos finances avant
              votre évènement ? Il est maintenant possible de créer un rapport
              d&apos;évènement avant sa date de fin.
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Un coup de pinceau ici et là',
        description: (
          <div>
            <Text type="secondary">
              Des textes non traduits ? des interfaces un peu grossière ? Pas de
              ça chez nous ! Nous mettons à jour l&apos;application
              régulièrement pour rendre votre expérience toujours optimale !
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.26.2',
    date: '13/05/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Gestion des catégories de facture',
        description: (
          <div>
            <Text type="secondary">
              Une nouvelle page est disponible dans les réglages pour éditer les
              catégories de facture.
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Une timetable encore plus simple à utiliser',
        description: (
          <div>
            <Text type="secondary">
              Le format des heures de la timetable s&apos;adapte maintenant à
              vos préférences de langue
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Mais où est passé la deuxième page',
        description: (
          <div>
            <Text type="secondary">
              Sur la page des factures d&apos;un évèment, il pouvait arriver que
              la pagination du tableau ne se mette pas à jour et empêche
              d&apos;accéder à toutes les factures. C&apos;est désormais de
              l&apos;histoire ancienne
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.27.0',
    date: '14/05/2024',
    fixtures: [
      {
        type: 'new',
        title: '📸 Allez on bouge plus ... souriez!',
        description: (
          <div>
            <Text type="secondary">
              Il est désormais possible d&apos;ajouter une photo de profil.
              Rendez-vous sur la page{' '}
              <Link to={'/account/profile'} style={{ color: 'white' }}>
                profil
              </Link>{' '}
              puis cliquez sur l&apos;avatar
              <br /> On peut ensuite retrouver sa petite tête un peu partout.
              Rien de mieux pour identifer en un clin d&apos;oeil qui a posté le
              dernier commentaire
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Bien commencer la semaine',
        description: (
          <div>
            <Text type="secondary">
              Il est désormais possible de personnaliser le premier jour des
              calendriers.
              <br /> Rendez-vous dans{' '}
              <Link
                to={'/settings/organization-settings'}
                style={{ color: 'white' }}
              >
                les préférences de {"l'organisation"}
              </Link>{' '}
              pour profiter de cette nouvelle option !
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.28.0',
    date: '16/05/2024',
    fixtures: [
      {
        type: 'new',
        title: "Archivage des statuts d'évènements",
        description: (
          <div>
            <Text type="secondary">
              Il est désormais possible d&apos;archiver les statuts
              d&apos;évènements que vous n&apos;utilisez plus
              <br />
              Pour profiter de cette nouvelle fonctionnalité rendez-vous dans{' '}
              <Link to={'/settings/event-statuses'} style={{ color: 'white' }}>
                les préférences de statuts d&apos;évènement
              </Link>
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Bug lors de la suppression de paiements',
        description: (
          <div>
            <Text type="secondary">
              Un bug s&apos;était glissé entre nos lignes de code et empêchait
              de supprimer les paiements sur les factures
              <br />
              On l&apos;a chassé et ce bug n&apos;est maintenant plus qu&apos;un
              lointain souvenir ...
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.29.0',
    date: '24/06/2024',
    fixtures: [
      {
        type: 'fix',
        title: 'Où est passée la timetable ?',
        description: (
          <div>
            <Text type="secondary">
              Lors d&apos;un passage de la page des grounds à la timetable, les
              performances n&apos;apparaissent plus ... On a rappelé tout se
              petit monde à l&apos;ordre et toute le monde est de retour.
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Correction des notifications de commentaire sur les factures',
        description: (
          <div>
            <Text type="secondary">
              Les liens sur les notifications de commentaire sur les factures
              n&apos;amenaient pas vers la facture
              <br />
              Tout est revenu dans l&apos;ordre. Vous pouvez enfin taguer
              Martine de la compta qui vous tanne pour avoir la facture de Leroy
              Merlin.
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Multi-devises 💶 💵 💴 💷',
        description: (
          <div>
            <Text type="secondary">
              Un booking en euro ? Une facture en dollar ? Une chambre
              d&apos;hôtel en yen ? Il est maintenant possible de choisir la
              devise de chaque ressource.
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Filtres & tris',
        description: (
          <div>
            <Text type="secondary">
              Des options de tri & filtrage ont été ajouté ici et là pour
              permettre d&apos;affiner les recherches en quelques clics
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Édition des types de bookings',
        description: (
          <div>
            <Text type="secondary">
              Les types de booking sont maintenant personnalisables depuis les
              réglages
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: "Besoin d'un coup de main ? 👋",
        description: (
          <div>
            <Text type="secondary">
              Vous pouvez utliser l&apos;outil d&apos;aide disponible en haut à
              droite{' '}
              <Text>
                <QuestionCircleOutlined />
              </Text>{' '}
              pour toute demande de support. Nos équipes vous répondent dans les
              plus bref délais.
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.30.0',
    date: '27/06/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Toujours plus de détails',
        description: (
          <div>
            <Text type="secondary">
              Il est maintenant possible de diviser le prix du booking en 3
              catégories: artist fee, production fee, booking fee
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.31.0',
    date: '29/06/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Édition des rôles & permissions',
        description: (
          <div>
            <Text type="secondary">
              Les rôles par défaut ne correspondent pas à ce dont vous avez
              besoin ? Qu&apos;à cela ne tienne !
              <br />
              Vous pouvez maintenant créer vos propres rôles et leur assigner
              des permissions pour coller à vos besoins.
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Gardez vos offer sheet bien au chaud',
        description: (
          <div>
            <Text type="secondary">
              Il est maintenant possible d&apos;attacher l&apos;offer sheet au
              booking
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.32.0',
    date: '08/07/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Ajout du type de performance',
        description: (
          <div>
            <Text type="secondary">
              Besoin de plus d&apos;informations sur la timetable comme les
              horaires du soundcheck, changement de plateau, ... ?
              <br />
              C&apos;est maintenant possible grâce au type de performance. Créez
              vos types de performance dans les réglages puis ajoutez-les sur la
              timetable.
              <br />
              Les type de performance sont aussi visibles sur la feuille de
              route !
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Un coup de pinceau ici et là',
        description: (
          <div>
            <Text type="secondary">
              Des textes non traduits ? des interfaces un peu grossière ? Pas de
              ça chez nous ! Nous mettons à jour l&apos;application
              régulièrement pour rendre votre expérience toujours optimale !
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.33.0',
    date: '30/07/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Notes sur les travels',
        description: (
          <div>
            <Text type="secondary">
              Il est maintenant possible d&apos;ajouter des notes sur les
              travels
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Export des travels & grounds',
        description: (
          <div>
            <Text type="secondary">
              Il est maintenant possible d&apos;exporter les travels & les
              grounds d&apos;un évènement
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.34.0',
    date: '31/07/2024',
    fixtures: [
      {
        type: 'new',
        title: "Export des chambres d'hôtels",
        description: (
          <div>
            <Text type="secondary">
              Il est maintenant possible d&apos;exporter les chambres
              d&apos;hôtels d&apos;un évènement
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.35.0',
    date: '04/08/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Choisissez votre fuseau horaire',
        description: (
          <div>
            <Text type="secondary">
              Ajout d&apos;un réglage de fuseau horaire dans les préférences de
              l&apos;organisation
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Toujours plus de détail',
        description: (
          <div>
            <Text type="secondary">
              Les dates dans le fuseau horaire de l&apos;organisation sont
              ajoutés aux exports de données
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.36.0',
    date: '08/08/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Les types de rider font leur apparition',
        description: (
          <div>
            <Text type="secondary">
              Beaucoup de riders ? Plusieurs riders pour le même artiste ? Juste
              envie de rajouter un petit quelque chose ? Qu&apos;à cela ne
              tienne ! Les types de rider sont ce qu&apos;il vous faut !
            </Text>
          </div>
        ),
      },
      {
        type: 'new',
        title: 'Rider sans fichier',
        description: (
          <div>
            <Text type="secondary">
              Les fichiers ne sont plus obligatoires dans un rider. Grâce à cela
              il est maintenant possible d&apos;ajouter vos riders textuels dans
              Alela ! Allleeeelaaaaaa !
            </Text>
          </div>
        ),
      },
      {
        type: 'fix',
        title: 'Améliorations diverses',
        description:
          "Parce qu'on aime le beau, on a mis quelques coups de pinceau ici et là pour vous proposer une interface toujours plus épurée",
      },
    ],
  },
  {
    version: 'v2.37.0',
    date: '13/10/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Seul on va plus vite, ensemble on va plus loin!',
        description: (
          <div>
            <Text type="secondary">
              Le module Marketing fait son apparition dans Alela, c&apos;est le
              moment d&apos;ajouter votre équipe de communication
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    version: 'v2.38.0',
    date: '24/11/2024',
    fixtures: [
      {
        type: 'new',
        title: 'Affichage de lhistorique des fichiers',
        description: (
          <div>
            <Text type="secondary">
              Lors du visionnage d&apos;un rider, contrat ou facture, il est
              désormais possible de voir l&apos;historique du fichier.
            </Text>
          </div>
        ),
      },
    ],
  },
]
