import {
  Divider,
  Form,
  InputNumber,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { numberRule, requiredRule } from '../../utils/rules'
import { useGroundContext } from '../../utils/context/GroundContext'
import { fieldValueIsValid } from '../../utils/helpers/GroundHelper'
import { CompassOutlined, LoadingOutlined } from '@ant-design/icons'

const { Text } = Typography

interface Props {}

const DurationFormItem = ({}: Props) => {
  const intl = useIntl()
  const form = Form.useFormInstance()
  const { getCoordinates, timeMode } = useGroundContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [canCalculate, setCanCalculate] = useState<boolean>(false)
  const pickupTime = Form.useWatch('pickupTime', form)
  const from = Form.useWatch('from', form)
  const to = Form.useWatch('to', form)

  const calculateDistance = async () => {
    const coordinates = getCoordinates()
    if (coordinates === undefined) return

    setIsLoading(true)

    const service = new google.maps.DistanceMatrixService()

    const departure = pickupTime.unix()
    const departureTime = new Date(departure)

    service
      .getDistanceMatrix({
        origins: [coordinates.from],
        destinations: [coordinates.to],
        travelMode: google.maps.TravelMode.DRIVING,
        transitOptions:
          timeMode === 'departAt'
            ? { departureTime: departureTime }
            : { arrivalTime: departureTime },
      })
      .then((response) => {
        try {
          const element = response.rows[0].elements[0]
          if (element.duration_in_traffic !== undefined) {
            form.setFieldValue(
              'duration',
              Math.floor(element.duration_in_traffic.value / 60)
            )
            return
          }
          if (element.duration !== undefined) {
            form.setFieldValue(
              'duration',
              Math.floor(element.duration.value / 60)
            )
          }
        } catch (error) {}

        setIsLoading(false)
      })
  }

  useEffect(() => {
    try {
      const timestamp = pickupTime.unix()
      const timeIsInFuture = timestamp > dayjs().unix()
      setCanCalculate(
        timeIsInFuture &&
          fieldValueIsValid(form.getFieldValue('from')) &&
          fieldValueIsValid(form.getFieldValue('to'))
      )
    } catch (error) {
      setCanCalculate(false)
    }
  }, [pickupTime, from, to])

  return (
    <Form.Item
      name="duration"
      rules={[requiredRule(intl), numberRule(intl)]}
      label={intl.formatMessage({
        id: LocalizationKeys.Misc.Form.Duration,
      })}
    >
      <InputNumber
        min={0}
        className="w-full"
        disabled={isLoading}
        addonAfter={
          <>
            mn
            <Divider type="vertical" />
            {isLoading && <Spin indicator={<LoadingOutlined />} spinning />}
            {!isLoading && (
              <Space
                style={{ cursor: 'pointer' }}
                onClick={canCalculate ? calculateDistance : () => {}}
              >
                <Tooltip
                  title={
                    <>
                      <Text>
                        {intl.formatMessage({
                          id: LocalizationKeys.Components.Ground
                            .CalculateDuration.Title,
                        })}
                      </Text>
                      <br />
                      <br />
                      <Text style={{ textAlign: 'justify' }}>
                        {intl.formatMessage({
                          id: LocalizationKeys.Components.Ground
                            .CalculateDuration.Explanation,
                        })}
                      </Text>
                    </>
                  }
                >
                  <CompassOutlined
                    disabled={!canCalculate}
                    style={{ opacity: canCalculate ? 1 : 0.5 }}
                  />
                </Tooltip>
              </Space>
            )}
          </>
        }
      />
    </Form.Item>
  )
}

type DurationFormItemType = { DurationFormItem: typeof DurationFormItem }

export { DurationFormItem, type DurationFormItemType }
