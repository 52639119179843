import { Card, Space, Tag, Tooltip, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { MarketingTask } from '../../models/MarketingTask'
import { selectMarketingTaskById } from '../../reducers/MarketingTaskReducer'
import {
  CalendarOutlined,
  ClockCircleOutlined,
  FileOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useSearchParams } from 'react-router-dom'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { User } from '../user/User'
import { selectEventById } from '../../reducers/EventReducer'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { useHasAccess } from '../HasAccess'
import { AlelaPermission } from '../../utils/permissions'

const { Text, Paragraph } = Typography

interface Props {
  id?: number
  model?: MarketingTask
  showEvent?: boolean
}

export const MarketingKanbanCard = ({
  id,
  model,
  showEvent = false,
}: Props) => {
  dayjs.extend(relativeTime)
  const [searchParams, setSearchParams] = useSearchParams()
  const task = model ? model : useAppSelector(selectMarketingTaskById(id ?? 0))
  const event = useAppSelector(selectEventById(task?.event ?? 0))
  const { hasAccess } = useHasAccess()

  if (task === undefined) return <></>

  const displayFooter =
    task.dueDate ||
    (task.attachementsCount ?? 0) > 0 ||
    (task.commentsCount ?? 0) > 0

  const canViewEvent = hasAccess([AlelaPermission.viewEvent])

  return (
    <Card
      styles={{ body: { padding: 10 } }}
      className="w-full"
      style={{ borderColor: false ? '#5e5df6' : undefined }}
      onClick={() =>
        addUrlParams(
          {
            action: 'update',
            entity: SBRMType.marketingTask,
            entityId: task.id.toString(),
          },
          undefined,
          setSearchParams
        )
      }
      cover={
        false ? (
          <img
            alt="example"
            height={150}
            width={'100%'}
            style={{ objectFit: 'cover' }}
            src={'task.cover'}
          />
        ) : undefined
      }
    >
      <Space direction="vertical" className="w-full" size={6}>
        <Paragraph
          className="m-0"
          style={{ wordBreak: 'keep-all', width: '100%' }}
          ellipsis={{ rows: 2, expandable: false }}
        >
          {task.title}
        </Paragraph>
        {displayFooter && (
          <Space
            direction="horizontal"
            className="w-full"
            style={{ justifyContent: 'space-between' }}
          >
            <Space direction="horizontal">
              {task.dueDate && (
                <Tooltip title={dayjs(task.dueDate).fromNow()}>
                  <Tag
                    color={
                      dayjs().diff(task.dueDate, 'day') < -5
                        ? 'green'
                        : dayjs().diff(task.dueDate, 'day') < 0
                        ? 'orange'
                        : 'red'
                    }
                    icon={<ClockCircleOutlined />}
                  >
                    {dayjs(task.dueDate).format('DD/MM')}
                  </Tag>
                </Tooltip>
              )}
              {(task.commentsCount ?? 0) > 0 && (
                <Text type="secondary">
                  <MessageOutlined /> {task.commentsCount}
                </Text>
              )}
              {(task.attachementsCount ?? 0) > 0 && (
                <Text type="secondary">
                  <FileOutlined /> {task.attachementsCount}
                </Text>
              )}
            </Space>
            {task.user && (
              <User.Avatar type="condensed" id={task.user} size={'small'} />
            )}
          </Space>
        )}
        {showEvent && task.event && event && (
          <ConditionalLinkWrapper
            condition={canViewEvent}
            to={`/events/${event.id}/line-up`}
          >
            <Tag icon={<CalendarOutlined />}>{event.name}</Tag>
          </ConditionalLinkWrapper>
        )}
      </Space>
    </Card>
  )
}

export type MarketingKanbanCardType = { KanbanCard: typeof MarketingKanbanCard }
