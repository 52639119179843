import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Col, Row, Space, Spin } from 'antd'
import { FilterValue } from 'antd/es/table/interface'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { useIntl } from 'react-intl'
import duration from 'dayjs/plugin/duration'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { MetaDataKey } from '../../../models/MetaData'
import { Ground } from '../../../models/Ground'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { getIdsFromArray } from '../../../utils/helpers/ReducerHelper'
import {
  getGrounds,
  selectGrounds,
  setGroundQuery,
} from '../../../reducers/GroundReducer'
import DescriptionModal from '../../../components/DescriptionModal'
import { TableParams } from '../../../models/TableParams'
import { Ground as GroundComponent } from '../../../components/ground/Ground'
import HasAccess from '../../../components/HasAccess'
import { AlelaPermission } from '../../../utils/permissions'
import { CarOutlined } from '@ant-design/icons'
import { Booking } from '../../../components/booking/Booking'
import SBTable from '../../../components/SBTable'
import { Contact } from '../../../components/contact/Contact'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'
import { selectContactsByIds } from '../../../reducers/ContactReducer'
import { Event } from '../../../components/event/Event'

const GroundPage = () => {
  dayjs.extend(duration)

  const intl = useIntl()
  const dispatch = useAppDispatch()

  const driversIdsFromGrounds = (grounds: Ground[]) =>
    getIdsFromArray(grounds, (ground) => ground.driver ?? 0)

  const event = useAppSelector(selectSelectedEvent())
  const grounds = useAppSelector(selectGrounds())
  const drivers = useAppSelector(
    selectContactsByIds(driversIdsFromGrounds(grounds))
  )

  const { query, isLoading } = useAppSelector((state) => state.ground)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [selectedTabBookingId, setSelectedTabBookingId] = useState<
    number | undefined
  >(undefined)

  const columns: ColumnsType<Ground> = [
    {
      key: 'bookings',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Grounds.Bookings,
      }),
      render: (record: Ground) => <Booking.Avatar id={record.booking ?? 0} />,
      width: 200,
    },
    {
      key: 'infos',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Grounds.Infos,
      }),
      width: 600,
      render: (record: Ground) => <GroundComponent.Infos id={record.id} />,
    },
    {
      key: 'drivers',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Grounds.Driver,
      }),
      filters: drivers.map((driver) => ({
        text: `${driver.firstName} ${driver.lastName}`,
        value: driver.id,
      })),
      render: (record: Ground) => (
        <Space direction="vertical" size={0}>
          {record.driver && (
            <Contact.Avatar
              id={record.driver}
              displayCompany={false}
              tag={record.vehicleDetails && record.vehicleDetails}
              tagIcon={<CarOutlined />}
            />
          )}
        </Space>
      ),
    },
    {
      key: 'passengers',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Grounds.Passengers,
      }),
      align: 'center',
      render: (record: Ground) =>
        record && <Contact.AvatarGroup ids={record.passengers} />,
    },
    {
      key: 'notes',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Grounds.Notes,
      }),
      render: (record: Ground) =>
        record.notes && (
          <DescriptionModal
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Grounds.GroundNotes,
            })}
            content={record.notes}
          />
        ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: Ground) => (
        <Actions
          actions={['update', 'duplicate', 'delete']}
          entity={SBRMType.ground}
          entityId={record.id}
          metadata={[{ key: MetaDataKey.eventId, value: event?.id }]}
        />
      ),
    },
  ]

  const handleTabChange = (nextBookingsFilter: FilterValue | null) => {
    const newQuery: TableParams = {
      ...query,
      filters: { ...query.filters, bookings: nextBookingsFilter },
    }
    dispatch(setGroundQuery(newQuery))
    dispatch(getGrounds(newQuery))

    if (nextBookingsFilter === null) {
      setSelectedTabBookingId(undefined)
    } else if (nextBookingsFilter.length === 1) {
      setSelectedTabBookingId(Number(nextBookingsFilter[0]))
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...filters, ...{ events: [event?.id!] } },
    }

    dispatch(setGroundQuery(newQuery))
    dispatch(getGrounds(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    // Trigger getVenues when SBRM is closed
    const newQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event?.id!] },
      columnKey: 'pickup_time',
      order: 'ascend',
    }
    dispatch(setGroundQuery(newQuery))
    dispatch(getGrounds(newQuery))
  }, [SBRMIsOpen])

  if (event === undefined) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listGround]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Grounds.Ground,
            })}
            level={5}
            badge={grounds.length}
            toolbar={
              <Space>
                {isLoading && <Spin />}
                <Actions
                  actions={['create']}
                  entity={SBRMType.ground}
                  metadata={[
                    { key: MetaDataKey.eventId, value: event.id },
                    { key: MetaDataKey.bookingId, value: selectedTabBookingId },
                  ]}
                />
                <Booking.Export eventId={event.id} entity={SBRMType.ground} />
                <Help.Button
                  article={HelpArticle.AddAndManageGroundTransfers}
                />
              </Space>
            }
          />
          <GroundComponent.ExternalRequests
            eventId={event.id}
            shouldRefreshList={() => {
              dispatch(getGrounds(query))
            }}
          />
          <Event.BookingFilter id={event.id} onChange={handleTabChange} />
          <SBTable
            entity={SBRMType.ground}
            emptyActionsMetadata={[
              { key: MetaDataKey.eventId, value: event.id },
              { key: MetaDataKey.bookingId, value: selectedTabBookingId },
            ]}
            scroll={{ x: 1000 }}
            rowKey={(record) => record.id}
            dataSource={grounds}
            loading={isLoading && !grounds.length}
            columns={columns}
            pagination={query.pagination}
            onChange={handleTableChange}
          />
        </HasAccess>
      </Col>
    </Row>
  )
}

export default GroundPage
