import { configureStore } from '@reduxjs/toolkit'
import SBRMReducer from './SBRMReducer'
import SearchReducer from './SearchReducer'
import ArtistReducer from './ArtistReducer'
import VenueReducer from './VenueReducer'
import StageReducer from './StageReducer'
import VenueTypeReducer from './VenueTypeReducer'
import EventReducer from './EventReducer'
import BookingReducer from './BookingReducer'
import EventStatusReducer from './EventStatusReducer'
import UserReducer from './UserReducer'
import BookingTypeReducer from './BookingTypeReducer'
import HotelReducer from './HotelReducer'
import RoomReducer from './RoomReducer'
import ContactReducer from './ContactReducer'
import ContactTypeReducer from './ContactTypeReducer'
import ContactAssociationReducer from './ContactAssociationReducer'
import FileReducer from './FileReducer'
import FileTypeReducer from './FileTypeReducer'
import TravelReducer from './TravelReducer'
import TravelTypeReducer from './TravelTypeReducer'
import RiderReducer from './RiderReducer'
import ContractReducer from './ContractReducer'
import ContractStatusReducer from './ContractStatusReducer'
import GroundReducer from './GroundReducer'
import InvoiceReducer from './InvoiceReducer'
import PerformanceReducer from './PerformanceReducer'
import PerformanceTypeReducer from './PerformanceTypeReducer'
import StationReducer from './StationReducer'
import StationTypeReducer from './StationTypeReducer'
import PublicLinkReducer from './PublicLinkReducer'
import RoleReducer from './RoleReducer'
import PermissionReducer from './PermissionReducer'
import AddressReducer from './AddressReducer'
import CalendarReducer from './CalendarReducer'
import InvoiceCategoryReducer from './InvoiceCategoryReducer'
import PaymentReducer from './PaymentReducer'
import SupplierReducer from './SupplierReducer'
import SupplierTypeReducer from './SupplierTypeReducer'
import InvitationReducer from './InvitationReducer'
import NotificationReducer from './NotificationReducer'
import RiderStatusReducer from './RiderStatusReducer'
import CommentReducer from './CommentReducer'
import ReportReducer from './ReportReducer'
import ReportItemReducer from './ReportItemReducer'
import TaskReducer from './TaskReducer'
import TaskStatusReducer from './TaskStatusReducer'
import ExternalAdvancingLinkReducer from './ExternalAdvancingLinkReducer'
import ExternalCommentReducer from './ExternalCommentReducer'
import ExternalUserReducer from './ExternalUserReducer'
import CurrencyReducer from './CurrencyReducer'
import MarketingTaskReducer from './MarketingTaskReducer'
import MarketingTaskStatusReducer from './MarketingTaskStatusReducer'
import RiderTypeReducer from './RiderTypeReducer'
import ActivityLogReducer from './ActivityLogReducer'

export const store = configureStore({
  reducer: {
    SBRM: SBRMReducer,
    search: SearchReducer,
    event: EventReducer,
    eventStatus: EventStatusReducer,
    artist: ArtistReducer,
    venue: VenueReducer,
    venueType: VenueTypeReducer,
    stage: StageReducer,
    booking: BookingReducer,
    bookingType: BookingTypeReducer,
    user: UserReducer,
    hotel: HotelReducer,
    room: RoomReducer,
    contact: ContactReducer,
    contactType: ContactTypeReducer,
    contactAssociation: ContactAssociationReducer,
    file: FileReducer,
    fileType: FileTypeReducer,
    contract: ContractReducer,
    contractStatus: ContractStatusReducer,
    invoice: InvoiceReducer,
    invoiceCategory: InvoiceCategoryReducer,
    payment: PaymentReducer,
    rider: RiderReducer,
    riderStatus: RiderStatusReducer,
    riderType: RiderTypeReducer,
    travel: TravelReducer,
    travelType: TravelTypeReducer,
    ground: GroundReducer,
    performance: PerformanceReducer,
    performanceType: PerformanceTypeReducer,
    station: StationReducer,
    stationType: StationTypeReducer,
    publicLink: PublicLinkReducer,
    role: RoleReducer,
    permission: PermissionReducer,
    address: AddressReducer,
    calendar: CalendarReducer,
    supplier: SupplierReducer,
    supplierType: SupplierTypeReducer,
    invitation: InvitationReducer,
    notification: NotificationReducer,
    comment: CommentReducer,
    externalComment: ExternalCommentReducer,
    report: ReportReducer,
    reportItem: ReportItemReducer,
    task: TaskReducer,
    taskStatus: TaskStatusReducer,
    externalAdvancingLink: ExternalAdvancingLinkReducer,
    externalUser: ExternalUserReducer,
    currency: CurrencyReducer,
    marketingTask: MarketingTaskReducer,
    marketingTaskStatus: MarketingTaskStatusReducer,
    activityLog: ActivityLogReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
