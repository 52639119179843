import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { TravelSearchSelection } from '../../../models/Travel'
import { MetaDataKey } from '../../../models/MetaData'
import { useAppSelector } from '../../../reducers/hooks'
import { Travel } from '@supplement-bacon/alela-uikit'
import { requiredRule, stringRule } from '../../../utils/rules'
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Tooltip,
} from 'antd'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import Icon from '@ant-design/icons/lib/components/Icon'
import {
  searchFlight,
  searchTrain,
  selectSelectedTravel,
} from '../../../reducers/TravelReducer'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import TextArea from 'antd/es/input/TextArea'
import { File } from '../../file/File'

interface Props {
  form: FormInstance
  bookingId?: number
  eventId?: number
  duplicate?: boolean
}

const Create = ({ form, bookingId, eventId, duplicate }: Props) => {
  const intl = useIntl()

  const [displayAllFields, setDisplayAllFields] = useState<boolean>(false)
  const [travelType, setTravelType] = useState<'flight' | 'train'>('flight')
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>(
    undefined
  )

  const travel = useAppSelector(selectSelectedTravel())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const chooseTravelSearch = (selection: TravelSearchSelection) => {
    form.setFieldValue('arrival', selection.arrival.id)
    form.setFieldValue('arrivalTime', dayjs(selection.arrivalTime))
    form.setFieldValue('departure', selection.departure.id)
    form.setFieldValue('departureTime', dayjs(selection.departureTime))
    setDisplayAllFields(true)
  }

  useEffect(() => {
    if (!SBRMIsOpen || duplicate) {
      // We want to trigger only the reset
      // when the SBRM opens and it's not for duplication
      return
    }
    form.resetFields()
    setDisplayAllFields(false)
    setTravelType('flight')
    setSelectedEventId(eventId)
    form.setFieldValue('event', eventId)
    form.setFieldValue('booking', bookingId)
    form.setFieldValue('type', 'flight')
  }, [duplicate, SBRMIsOpen])

  useEffect(() => {
    if (!duplicate || !travel) {
      return
    }
    setSelectedEventId(eventId)
    form.setFieldsValue(travel)
    form.setFieldValue('event', eventId)
    form.setFieldValue('booking', travel?.booking)
    form.setFieldValue('notes', travel?.notes)
    form.setFieldValue('inbound', travel.inbound ? '1' : '0')
    form.setFieldValue('type', travel.type === 1 ? 'train' : 'flight')
    form.setFieldValue('date', dayjs(travel.departureTime))
    form.setFieldValue('departureTime', dayjs(travel.departureTime))
    form.setFieldValue('arrivalTime', dayjs(travel.arrivalTime))
    setDisplayAllFields(true)
  }, [duplicate, travel])

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={() => {
        setSelectedEventId(form.getFieldValue('event'))
      }}
    >
      {!selectedEventId && (
        <SBAsyncSelect
          type={SBRMType.event}
          name={'event'}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Event,
          })}
          rules={[requiredRule(intl)]}
          metadata={[
            {
              key: MetaDataKey.eventId,
              value: eventId,
            },
          ]}
        />
      )}
      {selectedEventId && (
        <Form.Item name="event" rules={[requiredRule(intl)]} className="d-none">
          <Input type="hidden" />
        </Form.Item>
      )}
      {selectedEventId && (
        <SBAsyncSelect
          type={SBRMType.booking}
          name={'booking'}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Booking,
          })}
          rules={[requiredRule(intl)]}
          metadata={[
            {
              key: MetaDataKey.eventId,
              value: eventId,
            },
          ]}
        />
      )}

      <SBAsyncSelect
        type={SBRMType.contact}
        name="contacts"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Passengers,
        })}
        rules={[]}
        multiple
      />

      <Form.Item
        name="inbound"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.Type,
        })}
        rules={[requiredRule(intl)]}
      >
        <Radio.Group>
          <Radio.Button value={'1'}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Travel.Inbound,
            })}
          </Radio.Button>
          <Radio.Button value={'0'}>
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Travel.Outbound,
            })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Divider></Divider>

      <Card style={{ marginBottom: '24px' }}>
        <Row gutter={[16, 16]}>
          <Col md={6}>
            <Form.Item
              name="type"
              label={intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Travel.TransportType,
              })}
              rules={[requiredRule(intl)]}
            >
              <Radio.Group onChange={(e: any) => setTravelType(e.target.value)}>
                <Radio.Button value={'flight'}>
                  <Tooltip
                    title={intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.Travel.Plane,
                    })}
                  >
                    <Icon component={Travel.SVG.FlightOutbound} />
                  </Tooltip>
                </Radio.Button>
                <Radio.Button value={'train'}>
                  <Tooltip
                    title={intl.formatMessage({
                      id: LocalizationKeys.Misc.Form.Travel.Train,
                    })}
                  >
                    <Icon component={Travel.SVG.TrainInbound} />
                  </Tooltip>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col md={10}>
            <Form.Item
              name="date"
              label={intl.formatMessage({
                id: LocalizationKeys.Misc.Form.Date,
              })}
              rules={[requiredRule(intl)]}
            >
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name="number"
              label={intl.formatMessage({
                id:
                  travelType === 'flight'
                    ? LocalizationKeys.Misc.Form.Travel.FlightNumber
                    : LocalizationKeys.Misc.Form.Travel.TrainNumber,
              })}
              rules={[requiredRule(intl), stringRule(intl)]}
            >
              <Input
                placeholder={travelType === 'flight' ? 'AF6000' : '6106'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Travel.Searcher
              travelType={travelType}
              texts={{
                noTravelFound: intl.formatMessage({
                  id: LocalizationKeys.Components.Travel.Searcher.NoTravelFound,
                }),
                searchError: intl.formatMessage({
                  id: LocalizationKeys.Components.Travel.Searcher.Error,
                }),
                fillBeforeSearching: intl.formatMessage({
                  id: LocalizationKeys.Components.Travel.Searcher
                    .FillBeforeSearching,
                }),
                createManually: intl.formatMessage({
                  id: LocalizationKeys.Components.Travel.Searcher
                    .CreateManually,
                }),
                searchButton: intl.formatMessage({
                  id: LocalizationKeys.Components.Travel.Searcher.Search,
                }),
                searchInfos: {
                  selectTravel: intl.formatMessage({
                    id: LocalizationKeys.Components.Travel.Searcher.Select,
                  }),
                  changeStations: intl.formatMessage({
                    id: LocalizationKeys.Components.Travel.Searcher.Change,
                  }),
                  validateStations: intl.formatMessage({
                    id: LocalizationKeys.Components.Travel.Searcher.Validate,
                  }),
                },
              }}
              searchFlight={searchFlight}
              searchTrain={searchTrain}
              getNumberAndDate={() => ({
                number: form.getFieldValue('number'),
                date: dayjs(form.getFieldValue('date')).format('YYYY-MM-DD'),
              })}
              onTravelSearchSelected={chooseTravelSearch}
              onCreateManuallySelected={() => setDisplayAllFields(true)}
            />
          </Col>
        </Row>
      </Card>

      <SBAsyncSelect
        type={SBRMType.station}
        name="departure"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.Departure,
        })}
        rules={[requiredRule(intl)]}
        hidden={!displayAllFields}
        metadata={[
          {
            key: MetaDataKey.stationType,
            value: travelType === 'flight' ? 'airport' : 'train_station',
          },
        ]}
      />
      <Form.Item
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.DepartureTime,
        })}
        name="departureTime"
        rules={[requiredRule(intl)]}
        hidden={!displayAllFields}
      >
        <DatePicker
          className="w-full"
          format="YYYY-MM-DD HH:mm"
          showTime={{ format: 'HH:mm' }}
        />
      </Form.Item>
      <SBAsyncSelect
        type={SBRMType.station}
        name="arrival"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.Arrival,
        })}
        rules={[requiredRule(intl)]}
        hidden={!displayAllFields}
        metadata={[
          {
            key: MetaDataKey.stationType,
            value: travelType === 'flight' ? 'airport' : 'train_station',
          },
        ]}
      />
      <Form.Item
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Travel.ArrivalTime,
        })}
        name="arrivalTime"
        rules={[requiredRule(intl)]}
        hidden={!displayAllFields}
      >
        <DatePicker
          className="w-full"
          format="YYYY-MM-DD HH:mm"
          showTime={{ format: 'HH:mm' }}
        />
      </Form.Item>
      <Form.Item
        name="notes"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Notes,
        })}
      >
        <TextArea rows={5} />
      </Form.Item>
      <File.Dropzone name={'files'} multiple />
    </Form>
  )
}

export default Create
