import { useIntl } from 'react-intl'
import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  DatePicker,
} from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import TextArea from 'antd/es/input/TextArea'
import { fieldFormatter } from '../../../utils/formatters'
import { SBAsyncSelect } from '../../custom-inputs/sb-async-select/SBAsyncSelect'

import { MetaDataKey } from '../../../models/MetaData'
import { useEffect } from 'react'
import { selectSelectedRoom } from '../../../reducers/RoomReducer'
import dayjs from 'dayjs'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../../utils/hooks/useTenantSettings'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'

const { RangePicker } = DatePicker

interface Props {
  form: FormInstance
  eventId: number
  bookingId?: number
  duplicate?: boolean
}

const Create = ({ form, eventId, bookingId, duplicate }: Props) => {
  const intl = useIntl()
  const { currency } = useTenantSettings()

  const room = useAppSelector(selectSelectedRoom())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen || duplicate) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }

    form.resetFields()
    form.setFieldValue('booking', bookingId)
    if (currency !== undefined) {
      form.setFieldValue('currency', currency.id)
    }
  }, [duplicate, SBRMIsOpen])

  useEffect(() => {
    if (!duplicate || !room) {
      return
    }

    form.setFieldsValue(room)
    form.setFieldValue('booking', room.booking)
    form.setFieldValue('dates', [
      room.from ? dayjs(room.from) : '',
      room.to ? dayjs(room.to) : '',
    ])
  }, [duplicate, room])

  return (
    <Form form={form} layout="vertical">
      <SBAsyncSelect
        type={SBRMType.booking}
        name={'booking'}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Booking })}
        rules={[requiredRule(intl)]}
        metadata={[
          {
            key: MetaDataKey.eventId,
            value: eventId,
          },
        ]}
      />
      <SBAsyncSelect
        size="middle"
        type={SBRMType.hotel}
        name="hotel"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Room.Hotel,
        })}
        rules={[requiredRule(intl)]}
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="reference"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Room.Reference,
            })}
            rules={[stringRule(intl)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="category"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Room.RoomType,
            })}
            rules={[stringRule(intl)]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <SBAsyncSelect
        type={SBRMType.contact}
        name="contacts"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Room.Travelers,
        })}
        rules={[]}
        multiple={true}
      />

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            name="price"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Room.Price,
            })}
            rules={[numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="roomCredits"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Room.RoomCredits,
            })}
            rules={[numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <SBAsyncSelect
            size="middle"
            type={SBRMType.currency}
            name={'currency'}
            rules={[]}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Currency,
            })}
          />
        </Col>
      </Row>

      <Form.Item
        name="dates"
        rules={[]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Dates })}
      >
        <RangePicker
          className="w-full"
          showTime={{ format: 'HH:mm', minuteStep: 15 }}
          format="DD/MM/YYYY HH:mm"
        />
      </Form.Item>

      <Form.Item
        name="notes"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
      >
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  )
}

export default Create
