const BASE_API_URL = process.env.REACT_APP_API_URL
const API_URL = BASE_API_URL + '/api/v1'
const ACCOUNT_URL = API_URL + '/account'

const OAUTH_API_URL = process.env.REACT_APP_API_OAUTH_URL
export const LOGIN_URL = `${OAUTH_API_URL}/token`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REGISTER_URL = `${API_URL}/auth/register`
export const GET_LOGGED_USER_INFOS = `${API_URL}/auth/me`
export const USER_PREFERENCES_LOCALE = `${API_URL}/auth/me/preferences/locale`
export const USER_PICTURE_URL = `${API_URL}/auth/me/picture`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const GET_LOGGED_USER_ACCOUNT_INFOS = `${API_URL}/auth/account`
export const SAML_SSO_KEYS = `${API_URL}/auth/saml-sso`
export const UPDATE_ORGANIZATION_LOGO = `${API_URL}/auth/organization/logo`
export const ORGANIZATION_PERMISSIONS = `${API_URL}/auth/organization/permissions`

export const TENANT_URL = API_URL + '/tenant'
export const TENANT_BILLING_PORTAL_URL = TENANT_URL + '/billing/link'
export const TENANT_PLAN_URL = TENANT_URL + '/plan'
export const TENANT_USAGE_URL = TENANT_URL + '/usage'
export const TENANT_INVOICES_URL = TENANT_URL + '/invoices'
export const TENANT_INVITATIONS = TENANT_URL + '/invitations'
export const TENANT_SETTINGS_URL = TENANT_URL + '/settings'

export const PING_URL = API_URL + '/ping'
export const CUSTOM_ADDRESS_URL = API_URL + '/custom-addresses'
export const ROADSHEETS_URL = API_URL + '/roadsheets'
export const EVENTS_URL = API_URL + '/events'
export const EVENT_STATUSES_URL = API_URL + '/event-statuses'
export const HOTELS_URL = API_URL + '/hotels'
export const ROOMS_URL = API_URL + '/rooms'
export const BOOKINGS_URL = API_URL + '/bookings'
export const BOOKING_TYPES_URL = API_URL + '/booking-types'
export const FILES_URL = API_URL + '/files'
export const PUBLIC_LINKS_URL = API_URL + '/public-links'
export const RIDERS_URL = API_URL + '/riders'
export const RIDER_STATUSES_URL = API_URL + '/rider-statuses'
export const RIDER_TYPES_URL = API_URL + '/rider-types'
export const INVOICES_URL = API_URL + '/invoices'
export const INVOICE_CATEGORIES_URL = API_URL + '/invoice-categories'
export const PAYMENTS_URL = API_URL + '/payments'
export const SUPPLIERS_URL = API_URL + '/suppliers'
export const SUPPLIER_TYPES_URL = API_URL + '/supplier-types'
export const ARTISTS_URL = API_URL + '/artists'
export const STATIONS_URL = API_URL + '/stations'
export const STATION_TYPES_URL = API_URL + '/station-types'
export const CONTRACTS_URL = API_URL + '/contracts'
export const CONTRACT_STATUSES_URL = API_URL + '/contract-statuses'
export const VENUES_URL = API_URL + '/venues'
export const VENUE_TYPES_URL = API_URL + '/venue-types'
export const CONTACTS_URL = API_URL + '/contacts'
export const CONTACT_ASSOCIATION_URL = API_URL + '/contact-associations'
export const CONTACTS_MISSING_PARTY_CREW_URL =
  API_URL + '/contacts/missing-in-party-crew'
export const CONTACT_TYPES_URL = API_URL + '/contact-types'
export const PERFORMANCE_TYPES_URL = API_URL + '/performance-types'
export const STAGES_URL = API_URL + '/stages'
export const TRAVELS_URL = API_URL + '/travels'
export const TRAVEL_TYPE_URL = API_URL + '/travel-types'
export const PERFORMANCES_URL = API_URL + '/performances'
export const GROUND_URL = API_URL + '/ground'
export const REPORTS_URL = API_URL + '/reports'
export const REPORT_ITEMS_URL = API_URL + '/reports/items'
export const TASKS_URL = API_URL + '/tasks'
export const TASKS_STATUSES_URL = TASKS_URL + '/status'
export const WEATHER_URL = API_URL + '/reports/weather'
export const CURRENCIES_URL = API_URL + '/currencies'

export const ACCOUNT_USERS_URL = ACCOUNT_URL + '/users'
export const ROLES_URL = ACCOUNT_URL + '/roles'
export const USER_ROLE_URL = ACCOUNT_URL + '/profile'

export const NOTIFICATIONS_URL = API_URL + '/notifications'

export const COMMENTS_URL = API_URL + '/comments'
export const EXTERNAL_COMMENTS_URL = API_URL + '/external-comments'
export const COMMENTS_SUBSCRIPTION_URL = API_URL + '/comments/subscription'

export const EXTERNAL_ADVANCING_URL = API_URL + '/external-advancing'
export const EXTERNAL_ADVANCING_LINK_URL = EXTERNAL_ADVANCING_URL + '/links'

export const MARKETING_URL = API_URL + '/marketing'
export const MARKETING_TASK_URL = MARKETING_URL + '/tasks'
export const MARKETING_TASK_STATUS_URL = MARKETING_URL + '/task-statuses'

export const ACTIVITY_LOG_URL = API_URL + '/activity-logs'
/**
 * EXTERNAL ADVANCING
 */
const _EXTERNAL_ADVANCING_URL = BASE_API_URL + '/external-advancing/v1'

export const EXTERNAL_ADVANCING_REQUEST_MAGIC_LINK_URL =
  _EXTERNAL_ADVANCING_URL + '/auth/magic-link/send'
export const EXTERNAL_ADVANCING_LOGIN_URL =
  _EXTERNAL_ADVANCING_URL + '/auth/login'
export const EXTERNAL_ADVANCING_LOGOUT_URL =
  _EXTERNAL_ADVANCING_URL + '/auth/logout'
export const EXTERNAL_ADVANCING_LOGGED_USER_URL =
  _EXTERNAL_ADVANCING_URL + '/auth/me'

export const EXTERNAL_ADVANCING_USER_LINK_URL =
  _EXTERNAL_ADVANCING_URL + '/links'
